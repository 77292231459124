<template>
  <div class="singin">
    <div class="navbar">
      <van-icon
        @click="iconClick"
        name="down"
        color="rgba(0, 0, 0, 0.85)"
        size="24"
      />
      <span class="bankName">{{ $t("singin.bankName") }}</span>
      <span @click="cofClick" class="confirm">{{ $t("mine.done") }}</span>
    </div>
    <div class="Search">
      <van-search
        v-model="value"
        @search="searchChange"
        shape="round"
        background="#fafafa"
        placeholder="Search"
      />
    </div>
    <div>
      <div
        @click="cellCilck(item)"
        v-for="(item, index) in bankInfos"
        :key="index"
      >
        <van-cell v-if="id === item.id" :title="item.bankName" is-link>
          <template #right-icon>
            <van-icon
              name="success"
              class="search-icon"
              size="20"
              color="#3ad379"
            />
          </template>
        </van-cell>
        <van-cell v-else :title="item.bankName" />
      </div>
    </div>
  </div>
</template>

<script>
import { bankList } from "@/network/login";
export default {
  name: "bankNameList",
  data() {
    return {
      value: "",
      id: null,
      bankName: null,
      bankInfos: [],
      type: null,
      typeUrl: "",
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.type == "Y"
        ? (this.typeUrl = "/editBank")
        : (this.typeUrl = "/singin");
    }
    this.searchChange("");
  },
  methods: {
    cofClick() {
      if (this.bankName) {
        this.$router.push({
          path: this.typeUrl,
          query: {
            bankName: this.bankName,
            id: this.id,
          },
        });
      }
    },
    iconClick() {
      this.$router.push(this.typeUrl);
    },
    searchChange(val) {
      this.value = val;
      bankList({ name: this.value })
        .then((res) => {
          if(res.code === '10011'){
            return
          }
          if (res.code === "000000") {
            this.bankInfos = res.data.bankInfos;
          } else {
            this.$dialog
              .alert({
                message: res.message,
                confirmButtonText:this.$t('stringCode.Done'),
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          console.log(err,'err');
          this.$dialog
            .alert({
              message: err.message,
              confirmButtonText:this.$t('stringCode.Done'),
            })
            .then(() => {
              // on close
            });
        });
    },
    cellCilck(item) {
      this.id = item.id;
      this.bankName = item.bankName;
    },
  },
};
</script>

<style scoped>
.navbar {
  padding: 0 25px;
  display: flex;
  padding-top: 25px;
}
.navbar /deep/ .van-icon {
  vertical-align: middle;
  transform: rotate(90deg);
}
.bankName {
  vertical-align: middle;
  padding-left: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
}
.confirm {
  flex: 1;
  text-align: right;
  vertical-align: middle;
  font-weight: normal;
  font-size: 16px;
  color: #2dd070;
}
.Search {
  padding: 0 10px;
  margin-top: 20px;
}
.Search /deep/ .van-search__content {
  background-color: #ededed;
}
.singin {
  /* padding: 0 25px; */
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
</style>
